// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-default-js": () => import("/opt/build/repo/src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-contact-js": () => import("/opt/build/repo/src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-home-js": () => import("/opt/build/repo/src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-more-information-js": () => import("/opt/build/repo/src/templates/more-information.js" /* webpackChunkName: "component---src-templates-more-information-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

